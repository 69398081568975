import React from "react";

interface ISettingRowProps {
  label: string;
  input: JSX.Element;
  errorMessage?: string;
}

const SettingRow: React.FC<ISettingRowProps> = (props) => {
  return (
    <div className="row" style={{ margin: "20px" }}>
      <div className="column-50">
        <div>{props.label}</div>
        <div style={{ color: "red" }}>{props.errorMessage}</div>
      </div>
      <div className="column-50">{props.input}</div>
    </div>
  );
};

export default SettingRow;
