import React from "react";

import classes from "./NavigationItems.module.css";
import NavigationItem from "./NavigationItem/NavigationItem";

const NavigationItems = () => {
  return (
    <ul className={classes.NavigationItems}>
      <NavigationItem link="/overall">Tổng quan</NavigationItem>
      <NavigationItem link="/reviews">Đánh giá</NavigationItem>
      <NavigationItem link="/settings">Cài đặt</NavigationItem>
    </ul>
  );
};

export default NavigationItems;
