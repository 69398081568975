import { ActionTypes } from "./types/actionTypes";
import axios from "axios";
import { Dispatch } from "redux";
import { IDrivenSale } from "../reducers/drivenSales";
import * as DrivenSalesActionTypes from "./types/drivenSalesActions";

const requestStart: () => DrivenSalesActionTypes.IDrivenSalesRequestStart = () => ({
  type: ActionTypes.DRIVEN_SALES_REQUEST_START,
});

const requestFail: () => DrivenSalesActionTypes.IDrivenSalesRequestFail = () => ({
  type: ActionTypes.DRIVEN_SALES_REQUEST_FAIL,
});

const getDrivenSalesSuccess = (
  drivenSales: IDrivenSale[]
): DrivenSalesActionTypes.IDrivenSalesGetSuccess => {
  return {
    type: ActionTypes.DRIVEN_SALES_GET_SUCCESS,
    drivenSales,
  };
};

export const getDrivenSales = () => {
  return (dispatchEvent: Dispatch) => {
    dispatchEvent(requestStart());
    axios
      .get("/client/drivenSales")
      .then((res) => {
        dispatchEvent(getDrivenSalesSuccess(res.data));
      })
      .catch((e) => dispatchEvent(requestFail()));
  };
};
