import React, { ChangeEvent } from "react";
import { EFont } from "../../../../../store/reducers/settings";
import SettingRow from "../../SettingRow/SettingRow";

interface IFontProps {
  value: EFont;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const Font: React.FC<IFontProps> = (props) => {
  const input = (
    <select onChange={props.onChange} value={props.value}>
      <option value={EFont.Roboto}>Roboto</option>
      <option value={EFont.RobotoCondensed}>Roboto Condensed</option>
      <option value={EFont.RobotoMono}>Roboto Mono</option>
      <option value={EFont.RobotoSlab}>Roboto Slab</option>
      <option value={EFont.OpenSans}>Open Sans</option>
      <option value={EFont.Montserrat}>Montserrat</option>
      <option value={EFont.RaleWay}>Raleway</option>
      <option value={EFont.SourceSansPro}>Source Sans Pro</option>
      <option value={EFont.NotoSans}>Noto Sans</option>
      <option value={EFont.Oswald}>Oswald</option>
    </select>
  );
  return <SettingRow label="Phông chữ" input={input} />;
};

export default Font;
