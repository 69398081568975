import React, { ChangeEvent } from "react";
import { EReviewShadow } from "../../../../../store/reducers/settings";
import SettingRow from "../../SettingRow/SettingRow";

interface IReviewShadowProps {
  value: EReviewShadow;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const ReviewShadow: React.FC<IReviewShadowProps> = (props) => {
  const input = (
    <select onChange={props.onChange} value={props.value}>
      <option value={EReviewShadow.Basic}>Cơ bản</option>
      <option value={EReviewShadow.Light}>Mờ nhẹ</option>
      <option value={EReviewShadow.Dark}>Mờ nặng</option>
    </select>
  );

  return (
    <div>
      <SettingRow label="Bóng mờ của đánh giá" input={input} />
    </div>
  );
};

export default ReviewShadow;
