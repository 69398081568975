import React from "react";

import classes from "./SideDrawer.module.css";
import NavigationItems from "../NavigationItems/NavigationItems";
import Backdrop from "../../Elements/Backdrop/Backdrop";

interface SideDrawerProps {
  backdropClicked: () => void;
  showBackdrop: boolean;
  open: boolean;
}

const SideDrawer: React.FC<SideDrawerProps> = (props) => {
  return (
    <React.Fragment>
      <Backdrop
        clicked={props.backdropClicked}
        show={props.showBackdrop}
      ></Backdrop>
      <div
        className={[
          classes.SideDrawer,
          props.open ? classes.Open : classes.Close,
        ].join(" ")}
        onClick={props.backdropClicked}
      >
        <nav>
          <NavigationItems />
        </nav>
      </div>
    </React.Fragment>
  );
};

export default SideDrawer;
