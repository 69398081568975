import React, { ChangeEvent } from "react";
import classes from "./TimeSelection.module.css";

interface ITimeSelectionProps {
  value: "30" | "90" | "all";
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
}

const TimeSelection: React.FC<ITimeSelectionProps> = (props) => {
  return (
    <div>
      <select
        className={classes.TimeSelection}
        value={props.value}
        onChange={props.onChange}
      >
        <option value="30">30 ngày qua</option>
        <option value="90">90 ngày qua</option>
        <option value="all">Tất cả</option>
      </select>
    </div>
  );
};

export default TimeSelection;
