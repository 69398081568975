import React, { ChangeEvent, useState } from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import SendIcon from "@material-ui/icons/Send";
import classes from "./SendReply.module.css";

interface IRelpyProps {
  onCancel: () => void;
  onSend: (reply: string) => void;
}

const Reply: React.FC<IRelpyProps> = (props) => {
  const [text, setText] = useState<string>("");

  const replyChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value.trim());
  };

  const sendReplyHandler = (event: React.FormEvent) => {
    event.preventDefault();
    props.onSend(text);
  };

  return (
    <div className={classes.Row}>
      <form onSubmit={sendReplyHandler}>
        <textarea
          className={classes.TextAreaColumn}
          name="reply"
          rows={5}
          required
          placeholder="Trả lời..."
          onChange={replyChangeHandler}
        />
        <div className={classes.ButtonsColumn}>
          <button
            className={[classes.ReplyAction, classes.SendIcon].join(" ")}
            disabled={text.length ? false : true}
            type="submit"
          >
            <SendIcon />
          </button>
          <button
            className={[classes.ReplyAction, classes.CancelIcon].join(" ")}
            onClick={props.onCancel}
          >
            <CancelIcon />
          </button>
        </div>
      </form>
    </div>
  );
};

export default Reply;
