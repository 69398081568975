import React from "react";
import Card from "../../../../shared/components/Elements/Card/Card";
import { IDrivenSale } from "../../../../store/reducers/drivenSales";
import classes from "./DrivenSale.module.css";
import vndFormat from "../../../../shared/utils/vndFormat";

interface IDrivenSaleProps {
  drivenSale: IDrivenSale;
}

const DrivenSale: React.FC<IDrivenSaleProps> = (props) => {
  const { orderName, totalPrice, type } = props.drivenSale;
  let orderFrom: string | null = null;
  if (type === "Conversion") {
    orderFrom = "Đến từ Ảnh Nổi pop-up";
  } else if (type === "Retention") {
    orderFrom = "Đến từ Ảnh Nổi code";
  }

  return (
    <div className={classes.DrivenSale}>
      <Card>
        <div className={classes.Content}>
          <span className={classes.OrderName}>{orderName}</span>, trị giá{" "}
          <span className={classes.TotalPrice}>
            {vndFormat.format(totalPrice)}
          </span>
          <span className={classes.OrderFrom}>({orderFrom})</span>
        </div>
      </Card>
    </div>
  );
};

export default DrivenSale;
