import React, { useState } from "react";
import classes from "./Feedback.module.css";

interface IFeedbackProps {
  text: string;
}

const Feedback: React.FC<IFeedbackProps> = (props) => {
  const isTextLong = props.text.length > 1000;
  const [text, setText] = useState(
    isTextLong ? props.text.slice(0, 1000) : props.text
  );
  const [readMore, setReadMore] = useState(isTextLong);

  const readMoreClickHandler = () => {
    setText(props.text);
    setReadMore(false);
  };

  return (
    <div>
      {text}
      {readMore && (
        <span className={classes.ReadMore} onClick={readMoreClickHandler}>
          Xem thêm
        </span>
      )}
    </div>
  );
};

export default Feedback;
