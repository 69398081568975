import React, { ChangeEvent } from "react";
import SettingRow from "../../SettingRow/SettingRow";
import { EBoolean } from "../../../../../shared/types/types";

interface IPopupProps {
  value: EBoolean;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
}

const Popup: React.FC<IPopupProps> = (props) => {
  return (
    <SettingRow
      label="Pop-up"
      input={
        <select onChange={props.onChange} value={props.value}>
          <option value={EBoolean.True}>Hiện</option>
          <option value={EBoolean.False}>Ẩn</option>
        </select>
      }
    />
  );
};

export default Popup;
