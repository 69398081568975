import React, { useState, useEffect } from "react";
import { IWidgetCustom } from "../../../../../store/reducers/settings";
import SettingRow from "../../SettingRow/SettingRow";

interface IWidgetCustomProps {
  value: IWidgetCustom;
  onChange: <T extends keyof IWidgetCustom>(key: T, value: string) => void;
}

const WidgetCustom: React.FC<IWidgetCustomProps> = (props) => {
  const [updatedWidgetCustom, setUpdatedWidgetCustom] = useState(props.value);

  useEffect(() => {
    const fields = Object.keys(updatedWidgetCustom) as Array<
      keyof typeof updatedWidgetCustom
    >;
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      if (updatedWidgetCustom[field] !== props.value[field]) {
        props.onChange(field, updatedWidgetCustom[field]);
      }
    }
  }, [updatedWidgetCustom, props]);

  const {
    headerText,
    buttonBorder,
    buttonBackground,
    buttonBackgroundOnHover,
    buttonText,
    buttonTextOnHover,
    reviewBackground,
    reviewBackgroundOnHover,
    reviewText,
    verifiedBadge,
  } = updatedWidgetCustom;

  return (
    <div>
      <SettingRow
        label="Tiêu đề"
        input={
          <input
            type="color"
            value={headerText}
            onChange={(e) => {
              setUpdatedWidgetCustom({
                ...updatedWidgetCustom,
                headerText: e.target.value,
              });
            }}
          />
        }
      />

      <SettingRow
        label="Chữ nút"
        input={
          <input
            type="color"
            value={buttonText}
            onChange={(e) => {
              setUpdatedWidgetCustom({
                ...updatedWidgetCustom,
                buttonText: e.target.value,
              });
            }}
          />
        }
      />

      <SettingRow
        label="Nền nút"
        input={
          <input
            type="color"
            value={buttonBackground}
            onChange={(e) => {
              setUpdatedWidgetCustom({
                ...updatedWidgetCustom,
                buttonBackground: e.target.value,
              });
            }}
          />
        }
      />

      <SettingRow
        label="Chữ nút khi trỏ chuột"
        input={
          <input
            type="color"
            value={buttonTextOnHover}
            onChange={(e) => {
              setUpdatedWidgetCustom({
                ...updatedWidgetCustom,
                buttonTextOnHover: e.target.value,
              });
            }}
          />
        }
      />

      <SettingRow
        label="Nền nút khi trỏ chuột"
        input={
          <input
            type="color"
            value={buttonBackgroundOnHover}
            onChange={(e) => {
              setUpdatedWidgetCustom({
                ...updatedWidgetCustom,
                buttonBackgroundOnHover: e.target.value,
              });
            }}
          />
        }
      />

      <SettingRow
        label="Viền nút"
        input={
          <input
            type="color"
            value={buttonBorder}
            onChange={(e) => {
              setUpdatedWidgetCustom({
                ...updatedWidgetCustom,
                buttonBorder: e.target.value,
              });
            }}
          />
        }
      />

      <SettingRow
        label="Chữ đánh giá"
        input={
          <input
            type="color"
            value={reviewText}
            onChange={(e) => {
              setUpdatedWidgetCustom({
                ...updatedWidgetCustom,
                reviewText: e.target.value,
              });
            }}
          />
        }
      />

      <SettingRow
        label="Nền đánh giá"
        input={
          <input
            type="color"
            value={reviewBackground}
            onChange={(e) => {
              setUpdatedWidgetCustom({
                ...updatedWidgetCustom,
                reviewBackground: e.target.value,
              });
            }}
          />
        }
      />

      <SettingRow
        label="Nền đánh giá khi trỏ chuột"
        input={
          <input
            type="color"
            value={reviewBackgroundOnHover}
            onChange={(e) => {
              setUpdatedWidgetCustom({
                ...updatedWidgetCustom,
                reviewBackgroundOnHover: e.target.value,
              });
            }}
          />
        }
      />

      <SettingRow
        label="Dấu xác nhận đơn hàng"
        input={
          <input
            type="color"
            value={verifiedBadge}
            onChange={(e) => {
              setUpdatedWidgetCustom({
                ...updatedWidgetCustom,
                verifiedBadge: e.target.value,
              });
            }}
          />
        }
      />
    </div>
  );
};

export default WidgetCustom;
