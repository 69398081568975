import { ActionTypes } from "../actions/types/actionTypes";
import { OverallDrivenSalesActions } from "../actions/types/overallDrivenSalesActions";
import updateObject from "../../shared/utils/updateObject";

export interface IOverallDrivenSales {
  retention: { numberOfOrders: number; revenue: number };
  conversion: { numberOfOrders: number; revenue: number };
  isDiscount: boolean;
  showPopup: boolean;
}

export interface IOverallDrivenSalesReducers {
  overallDrivenSales: null | IOverallDrivenSales;
  loading: boolean;
  error: boolean;
}

const initialState: IOverallDrivenSalesReducers = {
  overallDrivenSales: null,
  loading: false,
  error: false,
};

const reducer = (
  state = initialState,
  action: OverallDrivenSalesActions
): IOverallDrivenSalesReducers => {
  switch (action.type) {
    case ActionTypes.OVERALL_DRIVEN_SALES_REQUEST_START:
      return updateObject(state, { loading: true, error: false });
    case ActionTypes.OVERALL_DRIVEN_SALES_REQUEST_FAIL:
      return updateObject(state, { loading: false, error: true });

    case ActionTypes.OVERALL_DRIVEN_SALES_FETCH_SUCCESS:
      return updateObject(state, {
        overallDrivenSales: action.overallDrivenSales,
        loading: false,
        error: false,
      });

    default:
      return state;
  }
};

export default reducer;
