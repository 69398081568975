import React, { useEffect, useState } from "react";
import DataCard from "../DataCard/DataCard";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../store/actions/index";
import { IStoreState } from "../../../../../store/reducers/index";
import StarRating from "./StarRating/StarRating";
import classes from "./OverallRating.module.css";
import TimeSelection from "../TimeSelection/TimeSelection";

const OverallRating = () => {
  const { overallRating, loading, error } = useSelector(
    (state: IStoreState) => state.overallRating
  );
  const [dataContent, setDataContent] = useState<null | JSX.Element>(null);
  const [numReviews, setNumReviews] = useState<null | number>(null);
  const [dateRange, setDateRange] = useState<"all" | "30" | "90">("all");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.fetchOverallRating());
  }, [dispatch]);

  useEffect(() => {
    if (overallRating) {
      const { oneStar, twoStar, threeStar, fourStar, fiveStar } = overallRating;
      const numberOfReviews =
        oneStar + twoStar + threeStar + fourStar + fiveStar;

      let percentages = {
        five: 0,
        four: 0,
        three: 0,
        two: 0,
        one: 0,
      };

      if (numberOfReviews > 0) {
        const five = Math.round((fiveStar / numberOfReviews) * 100);
        const four = Math.round((fourStar / numberOfReviews) * 100);
        const three = Math.round((threeStar / numberOfReviews) * 100);
        const two = Math.round((twoStar / numberOfReviews) * 100);
        const one = Math.round((oneStar / numberOfReviews) * 100);
        percentages = {
          five,
          four,
          three,
          two,
          one,
        };
      }

      const content = (
        <div className={classes.DataContent}>
          <StarRating
            ratingType={5}
            numberOfStars={fiveStar}
            percentage={percentages.five}
          />
          <StarRating
            ratingType={4}
            numberOfStars={fourStar}
            percentage={percentages.four}
          />
          <StarRating
            ratingType={3}
            numberOfStars={threeStar}
            percentage={percentages.three}
          />
          <StarRating
            ratingType={2}
            numberOfStars={twoStar}
            percentage={percentages.two}
          />
          <StarRating
            ratingType={1}
            numberOfStars={oneStar}
            percentage={percentages.one}
          />
        </div>
      );

      setNumReviews(numberOfReviews);
      setDataContent(content);
    }
  }, [overallRating]);

  const fetchOverallRatingInDateRange = (dateRange: "30" | "90" | "all") => {
    if (dateRange === "all") {
      dispatch(actions.fetchOverallRating());
    } else {
      dispatch(actions.fetchOverallRating(dateRange));
    }
  };

  return (
    <DataCard
      number={numReviews}
      numberMeaning="Đánh giá tổng cộng"
      content={dataContent}
      footer={
        <TimeSelection
          value={dateRange}
          onChange={(e) => {
            fetchOverallRatingInDateRange(
              e.target.value as "30" | "90" | "all"
            );
            setDateRange(e.target.value as "30" | "90" | "all");
          }}
        />
      }
      loading={loading}
      error={error}
    />
  );
};

export default OverallRating;
