import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IStoreState } from "../../../store/reducers";
import { getDrivenSales } from "../../../store/actions";
import DrivenSale from "../components/DrivenSale/DrivenSale";
import classes from "./DrivenSales.module.css";

const DrivenSales = () => {
  const { drivenSales } = useSelector(
    (state: IStoreState) => state.drivenSales
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDrivenSales());
  }, [dispatch]);

  return (
    <div className={classes.DrivenSales}>
      {/* SET SHOWMORE BUTTON */}
      {/* <div className={classes.Header}>Đơn hàng mới nhất</div>
      {salesContent} */}
      {drivenSales &&
        drivenSales.map((sale) => {
          return (
            <DrivenSale key={sale.orderName} drivenSale={sale}></DrivenSale>
          );
        })}
    </div>
  );
};

export default DrivenSales;
