import React, { ChangeEvent } from "react";
import { EReviewRadius } from "../../../../../store/reducers/settings";
import SettingRow from "../../SettingRow/SettingRow";

interface IReviewRadiusProps {
  value: EReviewRadius;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const ReviewRadius: React.FC<IReviewRadiusProps> = (props) => {
  const input = (
    <select onChange={props.onChange} value={props.value}>
      <option value={EReviewRadius.Sharp}>Nhọn</option>
      <option value={EReviewRadius.LightRounded}>Tròn nhẹ</option>
      <option value={EReviewRadius.Rounded}>Tròn</option>
      <option value={EReviewRadius.ExtraRounded}>Tròn nhiều</option>
    </select>
  );

  return (
    <div>
      <SettingRow label="Góc đánh giá" input={input} />
    </div>
  );
};

export default ReviewRadius;
