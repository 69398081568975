import React from "react";
import classes from "./Tooltip.module.css";

interface ITooltipProps {
  text: string;
  position: "Top" | "Bottom" | "Right" | "Left";
  showBorderBottom?: boolean;
}

const Tooltip: React.FC<ITooltipProps> = (props) => {
  return (
    <div
      className={
        props.showBorderBottom
          ? [classes.Tooltip, classes.ShowBorderBottom].join(" ")
          : classes.Tooltip
      }
    >
      {props.children}
      <span
        className={[classes.TooltipText, classes[props.position]].join(" ")}
      >
        {props.text}
      </span>
    </div>
  );
};

export default Tooltip;
