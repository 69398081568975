import React, { ChangeEvent } from "react";
import SettingRow from "../../SettingRow/SettingRow";
import {EBoolean} from "../../../../../shared/types/types";

interface IIsDiscountProps {
  value: EBoolean;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
}

const IsDiscount: React.FC<IIsDiscountProps> = (props) => {
  return (
    <SettingRow
      label="Giảm giá cho những đánh giá có hình ảnh"
      input={
        <select onChange={props.onChange} value={props.value}>
          <option value={EBoolean.True}>Bật</option>
          <option value={EBoolean.False}>Tắt</option>
        </select>
      }
    />
  );
};

export default IsDiscount;
