import { ActionTypes } from "../actions/types/actionTypes";
import { OverallRatingActions } from "../actions/types/overallRatingActions";
import updateObject from "../../shared/utils/updateObject";

export interface IOverallRating {
  oneStar: number;
  twoStar: number;
  threeStar: number;
  fourStar: number;
  fiveStar: number;
}

export interface IOverallRatingReducer {
  overallRating: IOverallRating | null;
  loading: boolean;
  error: boolean;
}

const initialState: IOverallRatingReducer = {
  overallRating: null,
  loading: false,
  error: false,
};

const reducer = (
  state = initialState,
  action: OverallRatingActions
): IOverallRatingReducer => {
  switch (action.type) {
    case ActionTypes.OVERALL_RATING_REQUEST_START:
      return updateObject(state, { loading: true, error: false });
    case ActionTypes.OVERALL_RATING_REQUEST_FAIL:
      return updateObject(state, { loading: false, error: true });

    case ActionTypes.FETCH_OVERALL_RATING_SUCCESS:
      return updateObject(state, {
        overallRating: action.overallRating,
        loading: false,
        error: false,
      });

    default:
      return state;
  }
};

export default reducer;
