import { ActionTypes } from "../actions/types/actionTypes";
import { DrivenSalesActions } from "../actions/types/drivenSalesActions";
import updateObject from "../../shared/utils/updateObject";

export interface IDrivenSale {
  orderName: string;
  totalPrice: number;
  type: "Retention" | "Conversion";
}

export interface IDrivenSalesReducers {
  drivenSales: null | IDrivenSale[];
  loading: boolean;
  error: boolean;
}

const initialState: IDrivenSalesReducers = {
  drivenSales: null,
  loading: false,
  error: false,
};

const reducer = (
  state = initialState,
  action: DrivenSalesActions
): IDrivenSalesReducers => {
  switch (action.type) {
    case ActionTypes.DRIVEN_SALES_REQUEST_START:
      return updateObject(state, { loading: true, error: false });
    case ActionTypes.DRIVEN_SALES_REQUEST_FAIL:
      return updateObject(state, { loading: false, error: true });

    case ActionTypes.DRIVEN_SALES_GET_SUCCESS:
      return updateObject(state, {
        drivenSales: action.drivenSales,
        loading: false,
        error: false,
      });

    default:
      return state;
  }
};

export default reducer;
