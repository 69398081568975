import { combineReducers } from "redux";
import overallRatingReducer, { IOverallRatingReducer } from "./overallRating";
import latestReviewsReducer, { ILatestReviewsReducers } from "./latestReviews";
import settingReducer, { ISettingReducer } from "./settings";
import overallDrivenSalesReducer, {
  IOverallDrivenSalesReducers,
} from "./overallDrivenSales";
import emailCreditsReducer, { IEmailCreditsReducers } from "./emailCredits";
import drivenSalesReducer, { IDrivenSalesReducers } from "./drivenSales";

export interface IStoreState {
  overallRating: IOverallRatingReducer;
  latestReviews: ILatestReviewsReducers;
  settings: ISettingReducer;
  overallDrivenSales: IOverallDrivenSalesReducers;
  emailCredits: IEmailCreditsReducers;
  drivenSales: IDrivenSalesReducers;
}

export default combineReducers<IStoreState>({
  overallRating: overallRatingReducer,
  latestReviews: latestReviewsReducer,
  settings: settingReducer,
  overallDrivenSales: overallDrivenSalesReducer,
  emailCredits: emailCreditsReducer,
  drivenSales: drivenSalesReducer,
});
