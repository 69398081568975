import React, { ChangeEvent } from "react";
import SettingRow from "../../SettingRow/SettingRow";

interface IStarColorProps {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const StarColor: React.FC<IStarColorProps> = (props) => {
  const input = (
    <input type="color" value={props.value} onChange={props.onChange} />
  );
  return <SettingRow label="Màu sao" input={input} />;
};

export default StarColor;
