import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  deleteReview,
  updateReview,
} from "../../../../../../../store/actions/index";
import classes from "./Actions.module.css";
import Button from "../../../../../../../shared/components/Elements/Button/Button";
import ConfirmModal from "../../../../../../../shared/components/Elements/ConfirmModal/ConfirmModal";

interface ActionsProps {
  reviewId: string;
  isReviewHidden: boolean;
  isReviewReplied: boolean;
  isReviewVerified: boolean;
  onHideToggleClick: () => void;
  onReplyClick: () => void;
}

const Actions: React.FC<ActionsProps> = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [moreActions, setMoreActions] = useState(false);
  const dispatch = useDispatch();
  const deleteReviewHandler = () => {
    dispatch(deleteReview(props.reviewId));
  };

  return (
    <div>
      <Button onClick={props.onHideToggleClick} inverse>
        {props.isReviewHidden ? "Hiện" : "Ẩn"}
      </Button>
      {!props.isReviewReplied && (
        <Button onClick={props.onReplyClick} inverse>
          Trả lời
        </Button>
      )}
      <Button onClick={() => setIsDeleting(true)} danger>
        Xóa
      </Button>
      <Button inverse onClick={() => setMoreActions(!moreActions)}>
        Khác
      </Button>
      {moreActions && (
        <div className={classes.MoreActions}>
          <Button
            size="small"
            inverse
            onClick={() => {
              dispatch(updateReview(props.reviewId, { isVerified: !props.isReviewVerified }));
            }}
          >
            {props.isReviewVerified ? "Hủy xác nhận đánh giá" : "Xác nhận đánh giá"}
          </Button>
        </div>
      )}
      <ConfirmModal
        isShowed={isDeleting}
        onCancelClick={() => setIsDeleting(false)}
        onConfirmClick={deleteReviewHandler}
      >
        Bạn có chắc chắn muốn xóa đánh giá này?
      </ConfirmModal>
    </div>
  );
};

export default Actions;
