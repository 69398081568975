import React from "react";
import Card from "../../../../shared/components/Elements/Card/Card";
import Button from "../../../../shared/components/Elements/Button/Button";
import classes from "./SettingField.module.css";

interface ISettingFieldProps {
  header: string;
  onSaveClick: () => void;
  isSaveBtnDisabled: boolean;
}

const SettingField: React.FC<ISettingFieldProps> = (props) => {
  return (
    <Card>
      <div className={classes.Header}>{props.header}</div>
      <div>{props.children}</div>
      <div className={classes.SaveBtn}>
        <Button
          inverse
          onClick={props.onSaveClick}
          disabled={props.isSaveBtnDisabled}
        >
          Lưu
        </Button>
      </div>
    </Card>
  );
};

export default SettingField;
