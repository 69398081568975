import React from "react";

import Spinner from "../Spinner/Spinner";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import classes from "./LoadingAreaHandler.module.css";

interface ILoadingAreaHandler {
  loading: boolean;
  error: boolean;
}

const LoadingAreaHandler: React.FC<ILoadingAreaHandler> = (props) => {
  const error = props.error && (
    <div className={classes.Error}>
      <div>
        <div className={classes.ErrorIcon}>
          <ErrorOutlineIcon />
        </div>
        <div>Đã xảy ra sự cố, xin vui lòng thử lại sau</div>
      </div>
    </div>
  );
  const spinner = props.loading && <Spinner overlay="area" />;
  return (
    <div>
      {error}
      {spinner}
    </div>
  );
};

export default LoadingAreaHandler;
