import React, { ChangeEvent } from "react";
import { EAutoPublish } from "../../../../../store/reducers/settings";
import SettingRow from "../../SettingRow/SettingRow";

interface IAutoPublishProps {
  value: EAutoPublish;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
}

const AutoPublish: React.FC<IAutoPublishProps> = (props) => {
  const input = (
    <select onChange={props.onChange} value={props.value}>
      <option value={EAutoPublish.All}>Tất cả</option>
      <option value={EAutoPublish.FiveStar}>5 sao</option>
      <option value={EAutoPublish.Above4}>4 sao trở lên</option>
      <option value={EAutoPublish.Above3}>3 sao trở lên</option>
      <option value={EAutoPublish.Off}>Tắt</option>
    </select>
  );
  return <SettingRow label="Tự động hiện đánh giá mới" input={input} />;
};

export default AutoPublish;
