import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Layout from "./containers/Layout/Layout";
import ReviewsContainer from "./containers/Reviews/pages/ReviewsContainer";
import Overall from "./containers/Overall/pages/Overall";
import Settings from "./containers/Settings/pages/Settings";
import DrivenSales from "./containers/DrivenSales/pages/DrivenSales";
import { getCookie } from "./shared/utils/cookie";
import { setAxiosHeader } from "./shared/utils/axiosHeaders";

function App() {
  const clientToken = getCookie("clientToken");
  const shopId = getCookie("shopId");
  if (clientToken && shopId) {
    setAxiosHeader(clientToken, shopId);
  }

  const routes = (
    <Switch>
      <Route exact path="/overall">
        <Overall />
      </Route>
      <Route exact path="/overall/:shopId/:clientToken">
        <Overall />
      </Route>
      <Route path="/reviews">
        <ReviewsContainer />
      </Route>
      <Route path="/drivenSales">
        <DrivenSales />
      </Route>
      <Route path="/settings">
        <Settings />
      </Route>
    </Switch>
  );

  return (
    <Router>
      <Layout>{routes}</Layout>
    </Router>
  );
}

export default App;
