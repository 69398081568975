import React from "react";
import classes from "./FiveStars.module.css";

interface IFiveStarsProps {
  rating: 1 | 2 | 3 | 4 | 5;
}

const FiveStars: React.FC<IFiveStarsProps> = (props) => {
  let fiveStars: JSX.Element[] = [];
  for (var i = 0; i < 5; i++) {
    if (props.rating > i) {
      fiveStars.push(<span key={i}>&#9733;</span>);
    } else {
      fiveStars.push(<span key={i}>&#9734;</span>);
    }
  }

  return <span className={classes.FiveStars}>{fiveStars.map((e) => e)}</span>;
};

export default FiveStars;
