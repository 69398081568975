import React from "react";

import "./Button.css";

interface IButtonProps {
  size?: string;
  inverse?: boolean;
  danger?: boolean;
  type?: "button" | "submit" | "reset";
  onClick: () => void;
  disabled?: boolean;
}

const Button: React.FC<IButtonProps> = (props) => {
  return (
    <button
      className={`anhnoiButton anhnoiButton--${props.size || "default"} ${
        props.inverse && "anhnoiButton--inverse"
      } ${props.danger && "anhnoiButton--danger"}`}
      type={props.type}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};

export default Button;
