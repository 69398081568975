import { Dispatch } from "redux";
import { ActionTypes } from "./types/actionTypes";
import * as SettingActions from "./types/settingActions";
import axios from "axios";
import { ISettings } from "../reducers/settings";

const requestStart = () => ({
  type: ActionTypes.SETTING_REQUEST_START,
});

const requestFail = (errorMessage?: string) => ({
  type: ActionTypes.SETTING_REQUEST_FAIL,
  errorMessage,
});

const fetchSettingSuccess = (settings: ISettings) => ({
  type: ActionTypes.FETCH_SETTING_SUCCESS,
  settings,
});

export const fetchSetting = () => {
  return (dispatchEvent: Dispatch) => {
    dispatchEvent(requestStart());
    axios
      .get("/client/settings/")
      .then((res) => {
        dispatchEvent(fetchSettingSuccess(res.data));
      })
      .catch((e) => dispatchEvent(requestFail()));
  };
};

const updateSettingSuccess = (
  updatedField: any
): SettingActions.IUpdateSettingSuccess => ({
  type: ActionTypes.UPDATE_SETTING_SUCCESS,
  updatedField,
});

export const updateSetting = (dataUpdate: any) => {
  return (dispatchEvent: Dispatch) => {
    dispatchEvent(requestStart());
    axios
      .patch(`/client/settings`, dataUpdate)
      .then((res) => {
        dispatchEvent(updateSettingSuccess(dataUpdate));
      })
      .catch((e) => {
        dispatchEvent(requestFail(e.response.data.message));
      });
  };
};
