import React, { useState } from "react";
import classes from "./Image.module.css";
import Backdrop from "../../../../../../../shared/components/Elements/Backdrop/Backdrop";

interface IImageProps {
  image500Url: string;
  image720Url: string;
}

const Image: React.FC<IImageProps> = (props) => {
  const [show720Image, setShow720Image] = useState(false);

  return (
    <div>
      <img
        className={classes.Image500}
        src={props.image500Url}
        alt="Ảnh đánh giá"
        onClick={() => setShow720Image(true)}
      />
      <Backdrop show={show720Image} clicked={() => setShow720Image(false)} />
      {show720Image && (
        <div className={classes.Image720Area}>
          <img
            className={classes.Image720}
            src={props.image720Url}
            alt="Ảnh đánh giá"
          />
        </div>
      )}
    </div>
  );
};

export default Image;
