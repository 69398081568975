import React from "react";

import classes from "./PercentageBar.module.css";

interface IPercentageBarProps {
  percentage: number;
}

const PercentageBar: React.FC<IPercentageBarProps> = (props) => {
  return (
    <div className={classes.PercentageBar}>
      <div
        style={{ width: `${props.percentage}%` }}
        className={classes.Percentage}
      ></div>
    </div>
  );
};

export default PercentageBar;
