import React from "react";

import classes from "./Toolbar.module.css";
import NavigationItems from "../NavigationItems/NavigationItems";
import SideDrawerToggle from "../SideDrawer/DrawerToggle/DrawerToggle";
import LogoImage from "../../../../assets/images/logo.png";

interface ToolbarProps {
  drawerToggleClicked: () => void;
}

const Toolbar: React.FC<ToolbarProps> = (props) => {
  return (
    <header className={classes.Toolbar}>
      <SideDrawerToggle clicked={props.drawerToggleClicked} />
      <div >
        <img alt="logo" src={LogoImage} className={classes.Logo} />
      </div>
      <nav className={classes.DesktopOnly}>
        <NavigationItems />
      </nav>
    </header>
  );
};

export default Toolbar;
