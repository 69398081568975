import React from "react";

import Card from "../../../../../shared/components/Elements/Card/Card";
import classes from "./DataCard.module.css";
import LoadingAreaHandler from "../../../../../shared/components/Elements/LoadingAreaHandler/LoadingAreaHandler";

interface DataCardProps {
  number: number | null;
  numberMeaning: string;
  content: JSX.Element | null;
  loading: boolean;
  error: boolean;
  footer?: any;
}

const DataCard: React.FC<DataCardProps> = (props) => {
  const header = (
    <div>
      <strong className={classes.Number}>{props.number}</strong>
      <span>{props.numberMeaning}</span>
    </div>
  );

  return (
    <Card>
      <div className={classes.DataArea}>
        <div className={classes.Header}>{header}</div>
        <div className={classes.Content}>{props.content}</div>
        <div className={classes.Footer}>{props.footer}</div>
      </div>
      <LoadingAreaHandler error={props.error} loading={props.loading} />
    </Card>
  );
};

export default DataCard;
