import React, { ChangeEvent } from "react";
import SettingRow from "../../SettingRow/SettingRow";

interface IDiscountContentProps {
  isValid: boolean;
  value?: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

const DiscountContent: React.FC<IDiscountContentProps> = (props) => {
  const input = (
    <textarea
      maxLength={100}
      rows={2}
      value={props.value}
      onChange={props.onChange}
      style={{ borderColor: props.isValid ? "transparent" : "red" }}
    />
  );

  return (
    <SettingRow
      label='Nội dung giảm giá (Vd. "Nhận code giảm giá 10%")'
      input={input}
      errorMessage={props.isValid ? undefined : "Không được trống"}
    />
  );
};

export default DiscountContent;
