import React, { ChangeEvent } from "react";
import SettingRow from "../../SettingRow/SettingRow";

interface IDiscountCodeProps {
  isValid: boolean;
  value?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const DiscountCode: React.FC<IDiscountCodeProps> = (props) => {
  const input = (
    <input
      type="text"
      value={props.value || ""}
      onChange={props.onChange}
      style={{ borderColor: props.isValid ? "transparent" : "red" }}
    />
  );
  return (
    <SettingRow
      label="Mã của bạn (lưu ý cần phải tạo mã giảm giá trước trong hệ thống rồi nhập vào đây)"
      input={input}
      errorMessage={props.isValid ? undefined : "Không được trống"}
    />
  );
};

export default DiscountCode;
