import React, { useState, useEffect } from "react";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import Spinner from "../Spinner/Spinner";

interface ILoadingScreenHandlerProps {
  error: boolean;
  loading: boolean;
  errorMessage?: string;
}

const LoadingScreenHandler: React.FC<ILoadingScreenHandlerProps> = (props) => {
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsError(props.error);
  }, [props.error]);
  
  const modal = (
    <Modal show={isError} backdropClicked={() => setIsError(false)}>
      <div style={{ textAlign: "center" }}>
        <div>
          {props.errorMessage
            ? props.errorMessage
            : "Đã xảy ra sự cố, xin vui lòng thử lại sau!"}
        </div>
        <div style={{ marginTop: "10px" }}>
          <Button size="small" onClick={() => setIsError(false)}>
            Đóng
          </Button>
        </div>
      </div>
    </Modal>
  );

  const spinner = props.loading && <Spinner overlay="screen" />;

  return (
    <div>
      {spinner}
      {modal}
    </div>
  );
};

export default LoadingScreenHandler;
