import React, { useState } from "react";
import classes from "./Video.module.css";
import Backdrop from "../../../../../../../shared/components/Elements/Backdrop/Backdrop";

interface IProps {
  url: string;
  thumbnailUrl: string;
}

const Video: React.FC<IProps> = (props) => {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <div>
      <div
        className={classes.ThumnailWrapper}
        onClick={() => setShowVideo(true)}
      >
        <img src={props.thumbnailUrl} alt="Video đánh giá" />
        <span className={`material-symbols-outlined ${classes.PlayBtn}`}>
          play_circle
        </span>
      </div>
      <Backdrop show={showVideo} clicked={() => setShowVideo(false)} />
      {showVideo && (
        <div className={classes.VideoWrapper}>
          <video controls autoPlay muted width="100%">
            <source src={props.url} type="video/mp4"></source>
            Trình duyệt không hỗ trợ thẻ video.
          </video>
        </div>
      )}
    </div>
  );
};

export default Video;
