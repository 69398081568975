import React from 'react';

import classes from './DrawerToggle.module.css';

interface SideDrawerProps {
    clicked: () => void
}

const SideDrawer: React.FC<SideDrawerProps> = (props) => (
    <div onClick={props.clicked} className={classes.DrawerToggle}>
        <div></div>
        <div></div>
        <div></div>
    </div>
)

export default SideDrawer;