import React, { useEffect, useState } from "react";
import Review from "./Review/Review";
import classes from "./LatestReviews.module.css";

import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../store/actions/index";
import { IStoreState } from "../../../../store/reducers/index";
import LoadingScreenHandler from "../../../../shared/components/Elements/LoadingScreenHandler/LoadingScreenHandler";
import Button from "../../../../shared/components/Elements/Button/Button";
import FilterBar from "./FilterBar/FilterBar";

const LatestReviews = () => {
  const { latestReviews, numberOfFound, error, loading } = useSelector(
    (state: IStoreState) => state.latestReviews
  );
  const [latestReviewsElement, setLatestReviewsElement] = useState<
    JSX.Element[] | null
  >(null);
  const [showLoadMoreBtn, setShowLoadMoreBtn] = useState(false);
  const [skip, setSkip] = useState(0);
  const [barQuery, setBarQuery] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchLatestReviews("skip=0"));
  }, [dispatch]);

  useEffect(() => {
    if (latestReviews) {
      const dateTimeFormat = new Intl.DateTimeFormat("vi-VN", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      });
      const e = latestReviews.map((review) => {
        const t = review.createdAt.split("T");
        const [year, month, date] = t[0].split("-");
        const [hour, minute] = t[1].split(":");
        const createdAtDate = new Date(
          Date.UTC(
            Number(year),
            Number(month) - 1,
            Number(date),
            Number(hour),
            Number(minute)
          )
        );
        const createdAtFormat = dateTimeFormat.format(createdAtDate);
        return (
          <Review
            key={review._id}
            review={{ ...review, createdAt: createdAtFormat }}
          />
        );
      });
      setLatestReviewsElement(e);
    }
  }, [latestReviews]);

  useEffect(() => {
    if (numberOfFound !== null && numberOfFound > skip + 20) {
      setShowLoadMoreBtn(true);
    } else {
      setShowLoadMoreBtn(false);
    }
  }, [numberOfFound, skip]);

  const loadMoreHandler = () => {
    dispatch(actions.fetchMoreReviews(`skip=${skip + 20}&${barQuery}`));
    setSkip(skip + 20);
  };

  const onSearchHandler = (query: string) => {
    dispatch(actions.fetchLatestReviews(`skip=0&${query}`));
    setBarQuery(query);
    setSkip(0);
  };

  return (
    <div>
      <div className={classes.Header}>Đánh giá mới nhất</div>
      <FilterBar onSearch={onSearchHandler} />
      {numberOfFound !== null && (
        <div style={{ margin: "20px auto" }}>{numberOfFound} kết quả</div>
      )}
      <div>{latestReviewsElement}</div>
      {showLoadMoreBtn && (
        <div className={classes.LoadMore}>
          <Button inverse onClick={loadMoreHandler}>
            Xem thêm
          </Button>
        </div>
      )}
      <LoadingScreenHandler error={error} loading={loading} />
    </div>
  );
};

export default LatestReviews;
