import React from "react";

import "./Spinner.css";

interface ISpinnerProps {
  overlay?: "screen" | "area";
}

const Spinner: React.FC<ISpinnerProps> = (props) => {
  return (
    <div
      className={
        props.overlay
          ? `loading-spinner__overlay loading-spinner__overlay-${props.overlay}`
          : undefined
      }
    >
      <div className="lds-dual-ring"></div>
    </div>
  );
};

export default Spinner;
