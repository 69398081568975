import React, { ChangeEvent } from "react";
import SettingRow from "../../SettingRow/SettingRow";
import { EBoolean } from "../../../../../shared/types/types";

interface IWriteReviewButtonProps {
  value: EBoolean;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
}

const WriteReviewButton: React.FC<IWriteReviewButtonProps> = (props) => {
  return (
    <SettingRow
      label='Nút "Viết đánh giá"'
      input={
        <select onChange={props.onChange} value={props.value}>
          <option value={EBoolean.True}>Hiện</option>
          <option value={EBoolean.False}>Ẩn</option>
        </select>
      }
    />
  );
};

export default WriteReviewButton;
