import React from "react";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import classes from "./ConfirmModal.module.css";

interface IConfirmModalProps {
  isShowed: boolean;
  onConfirmClick: () => void;
  onCancelClick: () => void;
}

const ConfirmModal: React.FC<IConfirmModalProps> = (props) => {
  return (
    <Modal show={props.isShowed} backdropClicked={props.onCancelClick}>
      <div className={classes.ConfirmModal}>
        <div>{props.children}</div>
        <div>
          <Button onClick={props.onCancelClick}>Hủy</Button>
          <Button onClick={props.onConfirmClick}>Ok</Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
