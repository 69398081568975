import React, { useState, useCallback } from "react";

import Toolbar from "../../shared/components/Navigation/Toolbar/Toolbar";
import SideDrawer from "../../shared/components/Navigation/SideDrawer/SideDrawer";
import classes from "./Layout.module.css";

const Layout = (props: any) => {
  const [sideDrawIsVisible, setsideDrawIsVisible] = useState(false);

  const toggleSideDrawerHandler = useCallback(() => {
    setsideDrawIsVisible(!sideDrawIsVisible);
  }, [setsideDrawIsVisible, sideDrawIsVisible]);

  const closeSideDrawerHandler = useCallback(() => {
    setsideDrawIsVisible(false);
  }, [setsideDrawIsVisible]);

  return (
    <React.Fragment>
      <Toolbar
        drawerToggleClicked={toggleSideDrawerHandler}
      />
      <SideDrawer
        open={sideDrawIsVisible}
        backdropClicked={closeSideDrawerHandler}
        showBackdrop={sideDrawIsVisible}
      />
      <main className={classes.Content}>{props.children}</main>
    </React.Fragment>
  );
};

export default Layout;
