import { ActionTypes } from "./types/actionTypes";
import axios from "axios";
import { Dispatch } from "redux";
import { IReview } from "../reducers/latestReviews";
import * as LatestReviewsActionTypes from "./types/latestReviewsActions";

const requestStart = (): LatestReviewsActionTypes.ILatestReviewsRequestStart => ({
  type: ActionTypes.LATEST_REVIEWS_REQUEST_START,
});

const requestFail = (): LatestReviewsActionTypes.ILatestReviewsRequestFail => ({
  type: ActionTypes.LATEST_REVIEWS_REQUEST_FAIL,
});

const fetchLatestReviewsSuccess = (
  latestReviews: IReview[],
  numberOfFound: number
): LatestReviewsActionTypes.ILatestReviewsRequestSuccess => {
  return {
    type: ActionTypes.LATEST_REVIEWS_REQUEST_SUCCESS,
    latestReviews,
    numberOfFound,
  };
};

export const fetchLatestReviews = (query: string) => {
  return (dispatchEvent: Dispatch) => {
    dispatchEvent(requestStart());
    axios
      .get("/client/review/latestReviews?" + query)
      .then((res) => {
        dispatchEvent(
          fetchLatestReviewsSuccess(res.data.reviews, res.data.length)
        );
      })
      .catch((e) => dispatchEvent(requestFail()));
  };
};

const fetchMoreReviewsSuccess = (
  moreReviews: IReview[]
): LatestReviewsActionTypes.IMoreReviewsRequestSuccess => {
  return {
    type: ActionTypes.MORE_REVIEWS_REQUEST_SUCCESS,
    moreReviews,
  };
};

export const fetchMoreReviews = (query: string) => {
  return (dispatchEvent: Dispatch) => {
    dispatchEvent(requestStart());
    axios
      .get("/client/review/latestReviews?" + query)
      .then((res) => {
        dispatchEvent(fetchMoreReviewsSuccess(res.data.reviews));
      })
      .catch((e) => dispatchEvent(requestFail()));
  };
};

const updateReviewSuccess = (
  updatedReview: IReview
): LatestReviewsActionTypes.IUpdateReviewSuccess => {
  return {
    type: ActionTypes.UPDATE_REVIEW_SUCCESS,
    updatedReview,
  };
};

export const updateReview = (
  reviewId: string,
  dataUpdate: { isHidden?: boolean; reply?: string; isVerified?: boolean }
) => {
  return (dispatchEvent: Dispatch) => {
    dispatchEvent(requestStart());
    axios
      .patch(`/client/review/${reviewId}`, dataUpdate)
      .then((res) => {
        dispatchEvent(updateReviewSuccess(res.data));
      })
      .catch((e) => {
        dispatchEvent(requestFail());
      });
  };
};

export const deleteReply = (reviewId: string) => {
  return (dispatchEvent: Dispatch) => {
    dispatchEvent(requestStart());
    axios
      .post(`/client/review/deleteReply/${reviewId}`)
      .then((res) => {
        dispatchEvent(updateReviewSuccess(res.data));
      })
      .catch((e) => {
        dispatchEvent(requestFail());
      });
  };
};

const deleteReviewSuccess = (
  deletedReviewId: string
): LatestReviewsActionTypes.IDeleteReviewSuccess => {
  return {
    type: ActionTypes.DELETE_REVIEW_SUCCESS,
    deletedReviewId,
  };
};

export const deleteReview = (deletedReviewId: string) => {
  return (dispatchEvent: Dispatch) => {
    dispatchEvent(requestStart());
    axios
      .delete(`/client/review/${deletedReviewId}`)
      .then((res) => {
        dispatchEvent(deleteReviewSuccess(deletedReviewId));
      })
      .catch((e) => {
        dispatchEvent(requestFail());
      });
  };
};