import React from "react";
import classes from "./ReplyText.module.css";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

interface IReplyTextProps {
  onEditClick: () => void;
  onDeleteClick: () => void;
}

const ReplyText: React.FC<IReplyTextProps> = (props) => {
  return (
    <div className={classes.ReplyTextRow}>
      <div className={classes.TextColumn}>Đã trả lời: {props.children}</div>
      <div className={classes.ButtonsColumn}>
        <button className={classes.EditIcon} onClick={props.onEditClick}>
          <EditIcon />
        </button>
        <button className={classes.DeleteIcon} onClick={props.onDeleteClick}>
          <DeleteIcon />
        </button>
      </div>
    </div>
  );
};

export default ReplyText;
