import React, { useState, ChangeEvent, FormEvent } from "react";
import Card from "../../../../../shared/components/Elements/Card/Card";
import classes from "./FilterBar.module.css";
import SearchIcon from "@material-ui/icons/Search";

interface IFilterBarProps {
  onSearch: (query: string) => void;
}

const FilterBar: React.FC<IFilterBarProps> = (props) => {
  const [repValue, setRepValue] = useState("");
  const [starValue, setStarValue] = useState(0);

  const searchHandler = (e: FormEvent) => {
    e.preventDefault();
    const query =
      (repValue.trim().length ? `rep=${repValue.trim()}&` : "") +
      (starValue ? `starRating=${starValue}&` : "");
    props.onSearch(query);
  };
  return (
    <Card>
      <form onSubmit={searchHandler}>
        <div className="row" style={{ textAlign: "center" }}>
          <div className="column-70">
            <input
              className={classes.Input}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setRepValue(e.target.value);
              }}
              placeholder="Tìm theo tên, email hoặc tên sản phẩm"
            />
          </div>
          <div className="column-20">
            <select
              className={classes.Input}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                setStarValue(Number(e.target.value));
              }}
            >
              <option value={0}>Mọi loại sao</option>
              <option value={5}>5 sao</option>
              <option value={4}>4 sao</option>
              <option value={3}>3 sao</option>
              <option value={2}>2 sao</option>
              <option value={1}>1 sao</option>
            </select>
          </div>
          <div className="column-10">
            <button className={classes.Search}>
              <SearchIcon />
            </button>
          </div>
        </div>
      </form>
    </Card>
  );
};

export default FilterBar;
