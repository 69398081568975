import React from "react";

import classes from "./StarRating.module.css";
import PercentageBar from "../../../../../../shared/components/Elements/PercentageBar/PercentageBar";

interface IStarRatingProps {
  ratingType: 1 | 2 | 3 | 4 | 5;
  numberOfStars: number;
  percentage: number;
}

const StarRating: React.FC<IStarRatingProps> = (props) => {
  const formatedNumberOfStars =
    props.numberOfStars >= 1000
      ? `${(props.numberOfStars / 1000).toFixed(1)}k`
      : props.numberOfStars;
  return (
    <div className={[classes.Row, "row"].join(" ")}>
      <span className={classes.StarTypeNumber}>{props.ratingType}</span>
      <span className={classes.Star}>&#9733;</span>
      <span className={classes.StarsNumber}>({formatedNumberOfStars})</span>

      <PercentageBar percentage={props.percentage} />
      <span className={classes.PercentageNumber}>{props.percentage}%</span>
    </div>
  );
};

export default StarRating;
