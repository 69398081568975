import React from "react";
import LatestReviews from "../components/LatestReviews/LatestReviews";
import classes from "./ReviewsContainer.module.css";

const ReviewsContainer = () => {
  
  return (
    <div className={classes.ReviewsContainer}>
      <LatestReviews />
    </div>
  );
};

export default ReviewsContainer;
