import { ActionTypes } from "./types/actionTypes";
import axios from "axios";
import { Dispatch } from "redux";
import { IOverallRating } from "../reducers/overallRating";
import * as OverallRatingActionTypes from "./types/overallRatingActions";

const requestStart: () => OverallRatingActionTypes.IOverallRatingRequestStart = () => ({
  type: ActionTypes.OVERALL_RATING_REQUEST_START,
});

const requestFail: () => OverallRatingActionTypes.IOverallRatingRequestFail = () => ({
  type: ActionTypes.OVERALL_RATING_REQUEST_FAIL,
});

const fetchOverallRatingSuccess = (
  overallRating: IOverallRating
): OverallRatingActionTypes.IOverallRatingRequestSuccess => {
  return {
    type: ActionTypes.FETCH_OVERALL_RATING_SUCCESS,
    overallRating,
  };
};

export const fetchOverallRating = (dateRange?: "30" | "90") => {
  return (dispatchEvent: Dispatch) => {
    dispatchEvent(requestStart());
    axios
      .get(
        "/client/overall/rating" +
          (dateRange ? `?dateRange=${dateRange}` : "")
      )
      .then((res) => {
        dispatchEvent(fetchOverallRatingSuccess(res.data.overallRating));
      })
      .catch((e) => dispatchEvent(requestFail()));
  };
};
