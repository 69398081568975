import React, { ChangeEvent } from "react";
import { EWidgetColors } from "../../../../../store/reducers/settings";
import SettingRow from "../../SettingRow/SettingRow";

interface IWidgetColorsProps {
  value: EWidgetColors;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const WidgetColors: React.FC<IWidgetColorsProps> = (props) => {
  const input = (
    <select onChange={props.onChange} value={props.value}>
      <option value={EWidgetColors.Black}>Đen (thích hợp cho nền sáng)</option>
      <option value={EWidgetColors.White}>Trắng (thích hợp cho nền tối)</option>
      <option value={EWidgetColors.Custom}>Tùy chỉnh</option>
    </select>
  );

  return (
    <div>
      <SettingRow label="Màu phông" input={input} />
    </div>
  );
};

export default WidgetColors;
