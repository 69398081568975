import { ActionTypes } from "../actions/types/actionTypes";
import { EmailCreditsActions } from "../actions/types/emailCreditsActions";
import updateObject from "../../shared/utils/updateObject";

export interface IEmailCredits {
  planCredits: number;
  creditsLeft: number;
}

export interface IEmailCreditsReducers {
  emailCredits: null | IEmailCredits;
  loading: boolean;
  error: boolean;
}

const initialState: IEmailCreditsReducers = {
  emailCredits: null,
  loading: false,
  error: false,
};

const reducer = (
  state = initialState,
  action: EmailCreditsActions
): IEmailCreditsReducers => {
  switch (action.type) {
    case ActionTypes.EMAIL_CREDITS_REQUEST_START:
      return updateObject(state, { loading: true, error: false });
    case ActionTypes.EMAIL_CREDITS_REQUEST_FAIL:
      return updateObject(state, { loading: false, error: true });

    case ActionTypes.EMAIL_CREDITS_FETCH_SUCCESS:
      return updateObject(state, {
        emailCredits: action.emailCredits,
        loading: false,
        error: false,
      });

    default:
      return state;
  }
};

export default reducer;
