import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import SettingField from "../SettingField/SettingField";
import AutoPublish from "./AutoPublish/AutoPublish";
// import EmailTiming from "./EmailTiming/EmailTiming";
import IsDiscount from "./IsDiscount/IsDiscount";
import DiscountCode from "./DiscountCode/DiscountCode";
import DiscountContent from "./DiscountContent/DiscountContent";
import { ICollectReview } from "../../../../store/reducers/settings";
import { updateSetting } from "../../../../store/actions";

interface ICollectReviewProps {
  collectReview: ICollectReview;
}

const CollectReview: React.FC<ICollectReviewProps> = (props) => {
  const [updatedCollectReview, setUpdatedCollectReview] = useState(
    props.collectReview
  );
  const [settingError, setSettingError] = useState<{
    discountContent: boolean;
    discountCode: boolean;
  }>({ discountContent: false, discountCode: false });
  const [updatedCollectReviewFields, setUpdatedCollectReviewFields] = useState(
    {}
  );
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    const keys = Object.keys(updatedCollectReview) as Array<
      keyof typeof updatedCollectReview
    >;
    let updatedFields = {};
    let isSame = true;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const currentCollectReviewFieldValue = props.collectReview[key];
      const currentUpdatedCollectReviewFieldValue = updatedCollectReview[key];
      if (
        currentCollectReviewFieldValue !== currentUpdatedCollectReviewFieldValue
      ) {
        updatedFields = {
          ...updatedFields,
          [key]: currentUpdatedCollectReviewFieldValue,
        };
        isSame = false;
      }
    }

    setIsBtnDisabled(isSame);
    setUpdatedCollectReviewFields(updatedFields);
  }, [props.collectReview, updatedCollectReview]);

  const saveButtonClickHandler = () => {
    const { discountCode, discountContent, isDiscount } = updatedCollectReview;
    let isDiscountContentError =
      (!discountContent || discountContent.trim().length === 0) && isDiscount;
    let isDiscountCodeError =
      (!discountCode || discountCode.trim().length === 0) && isDiscount;

    setSettingError({
      discountContent: isDiscountContentError,
      discountCode: isDiscountCodeError,
    });
    if (!isDiscountContentError && !isDiscountCodeError) {
      dispatch(updateSetting({ collectReview: updatedCollectReviewFields }));
    }
  };

  return (
    <SettingField
      header="Thu thập đánh giá"
      onSaveClick={saveButtonClickHandler}
      isSaveBtnDisabled={isBtnDisabled}
    >
      <AutoPublish
        value={updatedCollectReview.autoPublish}
        onChange={(e) => {
          setUpdatedCollectReview({
            ...updatedCollectReview,
            autoPublish: Number(e.target.value),
          });
        }}
      />
      {/* <EmailTiming
        value={updatedCollectReview.emailTiming}
        onChange={(e) => {
          setUpdatedCollectReview({
            ...updatedCollectReview,
            emailTiming: Number(e.target.value),
          });
        }}
      /> */}
      <IsDiscount
        value={Number(updatedCollectReview.isDiscount)}
        onChange={(e) => {
          setUpdatedCollectReview({
            ...updatedCollectReview,
            isDiscount: !!Number(e.target.value),
            discountCode: props.collectReview.discountCode,
            discountContent: props.collectReview.discountContent,
          });
        }}
      />
      {updatedCollectReview.isDiscount && (
        <div>
          <DiscountCode
            isValid={!settingError.discountCode}
            value={updatedCollectReview.discountCode}
            onChange={(e) => {
              setUpdatedCollectReview({
                ...updatedCollectReview,
                discountCode: e.target.value,
              });
            }}
          />
          <DiscountContent
            isValid={!settingError.discountContent}
            value={updatedCollectReview.discountContent}
            onChange={(e) => {
              setUpdatedCollectReview({
                ...updatedCollectReview,
                discountContent: e.target.value,
              });
            }}
          />
        </div>
      )}
    </SettingField>
  );
};

export default CollectReview;
