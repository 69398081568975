export enum ActionTypes {
    // OVERALL RATING
    OVERALL_RATING_REQUEST_START,
    OVERALL_RATING_REQUEST_FAIL,

    FETCH_OVERALL_RATING_SUCCESS,

    // OVERALL DRIVEN SALES
    OVERALL_DRIVEN_SALES_REQUEST_START,
    OVERALL_DRIVEN_SALES_REQUEST_FAIL,
    OVERALL_DRIVEN_SALES_FETCH_SUCCESS,

    // EMAIL CREDITS
    EMAIL_CREDITS_REQUEST_START,
    EMAIL_CREDITS_REQUEST_FAIL,
    EMAIL_CREDITS_FETCH_SUCCESS,

    // REVIEWS
    LATEST_REVIEWS_REQUEST_START,
    LATEST_REVIEWS_REQUEST_FAIL,
    
    LATEST_REVIEWS_REQUEST_SUCCESS,
    MORE_REVIEWS_REQUEST_SUCCESS,
    UPDATE_REVIEW_SUCCESS,
    DELETE_REVIEW_SUCCESS,

    // DRIVEN SALES
    DRIVEN_SALES_REQUEST_START,
    DRIVEN_SALES_REQUEST_FAIL,
    DRIVEN_SALES_GET_SUCCESS,

    // SETTING
    SETTING_REQUEST_START,
    SETTING_REQUEST_FAIL,
  
    FETCH_SETTING_SUCCESS,
    UPDATE_SETTING_SUCCESS,
}